import React from 'react'

function Sunset() {
  return (
    <div className='sunset'>

    </div>
  )
}

export default Sunset
